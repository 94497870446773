@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
  }

  body {
    @apply font-josefin;
  }

  a {
    @apply text-link-color no-underline transition-all duration-300;
  }

  a:hover {
    @apply text-link-hover;
  }

  h1 {
    @apply font-bold text-4xl leading-normal md:text-6xl md:leading-[60px];
  }

  h2 {
    @apply font-bold text-3xl md:text-[40px] md:leading-[44px];
  }

  h3 {
    @apply font-bold text-3xl;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-2.5 font-josefin;
  }

  ul,
  ol {
    @apply list-none m-0 p-0;
  }

  p {
    @apply pb-0 mb-4;
  }

  .btn-light {
    @apply bg-link-hover rounded-3xl uppercase text-sm font-bold  inline-block
        text-white pt-[19px] pb-[15px] px-10 leading-none transition-all;
  }

  .btn-light:hover {
    @apply bg-[#1D4F30] text-white;
  }

  .btn-white {
    @apply bg-white rounded-3xl uppercase text-sm font-bold inline-block
        text-[#00A7A5] pt-[19px] pb-[15px] px-10 leading-none transition-all;
  }

  .btn-white:hover {
    @apply bg-light-green text-white;
  }
}

/*======== Header Start ========*/

.main-menu li a:hover,
.main-menu li a.active {
  @apply bg-[#FAF6B3] text-[#0C671A];
}

.contactus .social-list a,
.header .social-list a {
  @apply text-[0] w-5 h-5 block transition-all;
}

.contactus .social-list li.instagram a,
.header .social-list li.instagram a {
  @apply block bg-[url('/images/instagram-dark.svg')] bg-no-repeat bg-center;
}

.contactus .social-list li.instagram a:hover,
.header .social-list li.instagram a:hover {
  @apply block bg-[url('/images/instagram-hover.svg')] bg-no-repeat bg-center;
}

.contactus .social-list li.tiktok a,
.header .social-list li.tiktok a {
  @apply block bg-[url('/images/tiktok-dark.svg')] bg-no-repeat bg-center;
}

.contactus .social-list li.tiktok a:hover,
.header .social-list li.tiktok a:hover {
  @apply block bg-[url('/images/tiktok-hover.svg')] bg-no-repeat bg-center;
}

.contactus .social-list li.pinterest a,
.header .social-list li.pinterest a {
  @apply block bg-[url('/images/pinterest-dark.svg')] bg-no-repeat bg-center;
}

.contactus .social-list li.pinterest a:hover,
.header .social-list li.pinterest a:hover {
  @apply block bg-[url('/images/pinterest-hover.svg')] bg-no-repeat bg-center;
}

.contactus .social-list li.facebook a,
.header .social-list li.facebook a {
  @apply block bg-[url('/images/facebook-dark.svg')] bg-no-repeat bg-center;
}

.contactus .social-list li.facebook a:hover,
.header .social-list li.facebook a:hover {
  @apply block bg-[url('/images/facebook-hover.svg')] bg-no-repeat bg-center;
}

.contactus .social-list li.twitter a,
.header .social-list li.twitter a {
  @apply block bg-[url('/images/twitter-dark.svg')] bg-no-repeat bg-center;
}

.contactus .social-list li.twitter a:hover,
.header .social-list li.twitter a:hover {
  @apply block bg-[url('/images/twitter-hover.svg')] bg-no-repeat bg-center;
}

.header .search-btn {
  @apply block bg-[url('/images/search.svg')] bg-no-repeat bg-center text-[0] w-5 h-5;
}

.responsive-menu .main-menu {
  @apply fixed -right-72 w-72 h-full overflow-y-auto bg-white z-[9999] top-0
    transition-all duration-300 invisible;
}
.responsive-menu .main-menu ul {
  @apply block p-4;
}
.responsive-menu .main-menu ul li {
  @apply m-0;
}
.responsive-menu .main-menu ul li a {
  @apply text-black py-2 md:py-3 block px-2 md:px-0 mb-0.5;
}
.responsive-menu .main-menu ul li a:hover,
.responsive-menu .main-menu ul li a.active {
  @apply text-link-color;
}
.responsive-menu .main-menu ul li .sub-menu {
  @apply opacity-100 visible static w-full rounded-none shadow-none py-0 m-0 bg-transparent;
}
.responsive-menu .main-menu.open {
  @apply visible right-0;
}
.responsive-menu .menu-overlay.active {
  @apply visible opacity-100;
}

/*======== Header End ========*/

/*======== Recent Article Start ========*/

.article-slider .articleCard-box {
  @apply px-4;
}

.article-slider .slick-list {
  @apply -mx-4;
}

.article-slider .slick-arrow {
  @apply absolute top-[110px] z-10 text-[0px] bg-no-repeat bg-center 
    w-[38px] h-[38px] shadow-c rounded-full;
}

.article-slider .slick-prev {
  @apply -left-2 md:-left-3 lg:-left-5 bg-[url('/images/arrow-left.svg')];
}

.article-slider .slick-prev:focus,
.article-slider .slick-prev:hover {
  @apply bg-[url('/images/arrow-left-hover.svg')];
}

.article-slider .slick-next {
  @apply -right-2 md:-right-3 lg:-right-5 bg-[url('/images/arrow-right.svg')];
}

.article-slider .slick-next:focus,
.article-slider .slick-next:hover {
  @apply bg-[url('/images/arrow-right-hover.svg')];
}

.article-slider .slick-arrow::before {
  display: none;
}

/*======== Recent Article End ========*/

/*======== Text Video block Start ========*/

.text-video-block .video-box iframe {
  @apply w-full h-full;
}

/*======== Text Video block End ========*/

/*======== Info block Start ========*/

.info-block .info-item {
  @apply rounded-2xl pt-12 pb-10 md:px-6 lg:px-20 w-full h-full bg-[#FCF4B0];
}

.info-block .info-item img {
  @apply mb-[50px];
}

/*======== Info block End ========*/

/*======== Footer Start ========*/

.footer .social-list {
  @apply flex flex-wrap space-x-5 mt-8 md:mt-[76px];
}

.footer .social-list a {
  @apply text-[0] w-5 h-5 block transition-all;
}

.footer .social-list li.instagram a {
  @apply block bg-[url('/images/instagram-light.svg')] bg-no-repeat bg-center;
}

.footer .social-list li.instagram a:hover {
  @apply block bg-[url('/images/instagram-hover.svg')] bg-no-repeat bg-center;
}

.footer .social-list li.tiktok a {
  @apply block bg-[url('/images/tiktok-light.svg')] bg-no-repeat bg-center;
}

.footer .social-list li.tiktok a:hover {
  @apply block bg-[url('/images/tiktok-hover.svg')] bg-no-repeat bg-center;
}

.footer .social-list li.pinterest a {
  @apply block bg-[url('/images/pinterest-light.svg')] bg-no-repeat bg-center;
}

.footer .social-list li.pinterest a:hover {
  @apply block bg-[url('/images/pinterest-hover.svg')] bg-no-repeat bg-center;
}

.footer .social-list li.facebook a {
  @apply block bg-[url('/images/facebook-light.svg')] bg-no-repeat bg-center;
}

.footer .social-list li.facebook a:hover {
  @apply block bg-[url('/images/facebook-hover.svg')] bg-no-repeat bg-center;
}

.footer .social-list li.twitter a {
  @apply block bg-[url('/images/twitter-light.svg')] bg-no-repeat bg-center;
}

.footer .social-list li.twitter a:hover {
  @apply block bg-[url('/images/twitter-hover.svg')] bg-no-repeat bg-center;
}

.footer .footer-menu li a:hover,
.footer .footer-menu li a.active {
  @apply text-link-hover underline;
}

.footer .newsletter-form {
  @apply max-w-[250px];
}

.footer .newsletter-form .email {
  @apply w-full border border-white rounded-3xl outline-none h-12 px-5 py-0 bg-transparent max-w-[244px] mb-2.5
    font-normal text-white text-sm;
}

.footer .newsletter-form .btn-light {
  @apply w-full max-w-[244px];
}

/*======== Footer End ========*/

/*======== Inner page Start ========*/
ul.breadcrumb {
  @apply flex flex-wrap;
}

ul.breadcrumb li {
  @apply uppercase text-xs text-[#5A5A5A] relative pr-5;
}

ul.breadcrumb li::before {
  @apply content-[""] bg-[#5A5A5A] w-1 h-1 rounded-full absolute top-1/2 -translate-y-1/2 -left-3;
}

ul.breadcrumb li:nth-child(1):before {
  @apply hidden;
}

ul.breadcrumb li a {
  @apply uppercase text-xs text-[#5A5A5A] no-underline;
}

.banner-text-area {
  @apply flex flex-col md:pt-[30px];
}

.banner-text-area h6 {
  @apply max-w-[690px] mx-auto;
}

.inner-hero .sub-category li a:hover,
.inner-hero .sub-category li a.active {
  @apply text-[#0C671A] underline;
}

.articleCard-box {
  @apply sm:w-1/2 sm:px-3 md:px-4 pb-10 md:pb-16 md:w-1/3;
}

.articleCard-wrap.featured .articleCard-group .articleCard-box {
  @apply sm:w-1/2;
}

.articleCard-wrap.featured
  .articleCard-group
  .articleCard-box
  + .articleCard-box {
  @apply pt-10 sm:pt-0;
}

.articleCard-wrap.featured .articleCard-group .articleCard-box:nth-child(odd) {
  @apply md:pr-6;
}

.articleCard-wrap.featured .articleCard-group .articleCard-box:nth-child(even) {
  @apply md:pl-6;
}

.articleCard-group {
  @apply md:-mx-4 sm:-mx-3;
}

.pagination .previous a {
  @apply bg-[url('/images/icon-prev.svg')] bg-no-repeat bg-center text-[0] w-8 h-8;
}

.pagination .next a {
  @apply bg-[url('/images/icon-next.svg')] bg-no-repeat bg-center text-[0] w-8 h-8;
}

.pagination li a {
  @apply min-w-[32px] rounded-sm;
}

.pagination li a.active {
  @apply bg-link-hover text-white;
}

/*======== Inner page End ========*/

/*======== Artical page Start ========*/

.single-article .author-list {
  @apply mt-4;
}

.single-article .author-list ul {
  @apply flex flex-wrap justify-center items-center space-x-7;
}

.single-article .author-list ul li {
  @apply relative text-lg text-black font-albert;
}

.single-article .author-list ul li p {
  @apply hidden;
}

.single-article .author-list ul li::before {
  @apply content-[""] bg-[#555555] w-px h-4 absolute -left-[14px] top-[5px];
}

.single-article .author-list ul li:nth-child(1):before {
  @apply hidden;
}

.single-article .author-list ul li a {
  @apply relative font-albert text-lg text-[#0C671A] no-underline hover:underline;
}

.single-article .sub-category ul {
  @apply flex justify-center items-center flex-wrap gap-5 mt-8;
}

.single-article .sub-category ul li a {
  @apply font-semibold text-base text-link-color uppercase no-underline hover:text-[#0C671A] hover:underline;
}

.single-article .left-side-content-menu ol li {
  @apply mb-4 relative;
}

.single-article .left-side-content-menu ol li.active a {
  @apply text-[#0C671A] underline;
}

.single-article .left-side-content-menu ol li.active a::after,
.single-article .left-side-content-menu ol li:hover::after {
  @apply absolute -left-6 top-1/2 block h-[110%] min-h-[36px] w-[3px] -translate-y-1/2 bg-[#0C671A] content-[""];
}

.single-article .left-side-content-menu .social-wrap li {
  @apply pr-1;
}

.single-article .left-side-content-menu .social-wrap li a {
  @apply w-[34px] h-[34px] text-[0] block;
}

.single-article .left-side-content-menu .social-wrap li.faceboox a {
  @apply bg-[url('/images/facebook.svg')] bg-no-repeat bg-center;
}

.single-article .left-side-content-menu .social-wrap li.faceboox a:hover {
  @apply bg-[url('/images/facebook-green.svg')] bg-no-repeat bg-center;
}

.single-article .left-side-content-menu .social-wrap li.twitter a {
  @apply bg-[url('/images/twitter.svg')] bg-no-repeat bg-center;
}

.single-article .left-side-content-menu .social-wrap li.twitter a:hover {
  @apply bg-[url('/images/twitter-green.svg')] bg-no-repeat bg-center;
}

.single-article .left-side-content-menu .social-wrap li.reddit a {
  @apply bg-[url('/images/reddit.svg')] bg-no-repeat bg-center;
}

.single-article .left-side-content-menu .social-wrap li.reddit a:hover {
  @apply bg-[url('/images/reddit-hover.svg')] bg-no-repeat bg-center;
}

.single-article .left-side-content-menu .social-wrap li.linkedin a {
  @apply bg-[url('/images/linkedin.svg')] bg-no-repeat bg-center;
}

.single-article .left-side-content-menu .social-wrap li.linkedin a:hover {
  @apply bg-[url('/images/linkedin-hover.svg')] bg-no-repeat bg-center;
}

.single-article .left-side-content-menu .social-wrap li.mail a {
  @apply bg-[url('/images/mail.svg')] bg-no-repeat bg-center;
}

.single-article .left-side-content-menu .social-wrap li.mail a:hover {
  @apply bg-[url('/images/mail-hover.svg')] bg-no-repeat bg-center;
}

.single-article .content-block a {
  @apply text-[#0C671A] no-underline hover:underline;
}

.single-article .content-block blockquote {
  @apply px-7 py-4 mb-10;
}

.single-article .content-block blockquote h4 {
  @apply font-josefin font-normal text-xl sm:text-[28px] text-[#00A7A5];
}

.single-article .content-block blockquote p:nth-last-child(1) {
  @apply m-0 p-0;
}

.single-article .content-block ol {
  @apply list-decimal list-inside mb-0;
}

.single-article .content-block ol li::marker {
  @apply text-[#00A7A5] font-bold text-xl;
}

.single-article .content-block ol li p {
  @apply inline-block pb-0;
}

.single-article .content-block ul {
  @apply list-none list-inside mb-0;
}

.single-article .content-block ul li {
  @apply relative pl-5;
}
.single-article .content-block figure.media {
  @apply w-full my-5;
}

.single-article .content-block ul li::before {
  @apply content-[""] bg-link-hover w-[7px] h-[7px] absolute left-0 top-2.5;
}

.single-article .content-block table {
  @apply mb-10 w-full;
}

.single-article .content-block table thead tr th {
  @apply border-r border-[#E4E7EC] bg-green  px-5 py-3 pl-4 pr-3 text-sm
   text-white sm:pl-6 lg:whitespace-nowrap lg:text-lg;
}
.single-article .content-block table thead tr th:last-child {
  @apply border-r-0;
}
.single-article .content-block table tbody tr td {
  @apply bg-green/25;
}
.single-article .content-block table thead tr td {
  @apply bg-white;
}
.single-article .content-block table tbody tr:nth-child(odd) td {
  @apply border-inherit bg-white;
}

.single-article .content-block table tr th:first-child {
  @apply rounded-tl-lg;
}

.single-article .content-block table tr th:last-child {
  @apply rounded-tr-lg;
}

.single-article .content-block table tr td {
  @apply border border-inherit bg-white px-5 py-3 pl-4 pr-3 text-sm text-black sm:pl-6 lg:whitespace-nowrap lg:text-lg;
}

.single-article .content-block table tr:last-child td {
  @apply border-b border-r;
}

.single-article .content-block table tbody tr:last-child td:first-child {
  @apply border-b border-l;
}

.single-article .content-block table tbody tr:last-child td:last-child {
  @apply border-b border-r;
}

.single-article .content-block h5 {
  @apply m-0 p-0;
}

.single-article .contentCol ::-moz-selection {
  @apply bg-[#73D8C9];
}

.single-article .contentCol ::selection {
  @apply bg-[#73D8C9];
}

.single-article .responsive-iframe {
  @apply relative h-0 w-full mb-9 overflow-hidden pb-[46%];
}

.single-article .responsive-iframe iframe {
  @apply absolute inset-0 w-full h-full;
}

.single-article .about-author {
  @apply text-left font-albert font-normal text-xs text-[#1A1A1A]/70 border-t border-[#E2E2E2] mt-10 pt-12;
}

.single-article .about-author ul li {
  @apply font-albert font-normal text-base text-black pr-3;
}

.single-article .about-author p,
.single-article .about-author ul li p {
  @apply font-albert font-normal text-xs text-[#1A1A1A]/70 m-0 p-0 mt-2;
}

.single-article .about-author ul li a {
  @apply text-[#0C671A] underline;
}

.single-article .about-author ul li a:hover {
  @apply no-underline;
}

/*======== Artical page End ========*/

/*======== Contact us page ========*/

.contact-form label {
  @apply block text-base text-black mb-2.5;
}

.contact-form label span {
  @apply text-[#FF0000];
}

.contact-form input {
  @apply border border-[#E2E2E2] rounded h-11 px-3 py-0 w-full;
}

.contact-form input:focus {
  @apply border-black;
}

.contact-form textarea {
  @apply w-full h-[100px] border border-[#E2E2E2] rounded p-3 resize-none;
}

.contact-form .btn-submit {
  @apply mt-6 bg-link-color w-36 h-12 rounded-lg text-white font-semibold uppercase leading-[3.3rem];
}

.contact-form .btn-submit:hover {
  @apply bg-link-hover;
}

.cookie-policy-content a {
  @apply underline;
}

.cookie-policy-content a:hover {
  @apply no-underline;
}

/*======== Artical page End ========*/

@media (max-width: 991.98px) {
  .intro-hero {
    background-image: none !important;
  }
}

@media (min-width: 1360px) {
  .single-article .left-side-content-menu {
    @apply -ml-[330px] w-[330px] pr-0;
  }
}

@media (min-width: 1660px) {
  .single-article .left-side-content-menu {
    @apply -ml-[380px] w-[380px] pr-10;
  }
}
